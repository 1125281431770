<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockEventsCarousel } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockEventsCarousel<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

const carouselSettings = {
  itemsToShow: 2,
  itemsToScroll: 1,
  snapAlign: "start",
};

const carouselSettingsMobile = {
  itemsToShow: 1,
  itemsToScroll: 1,
  snapAlign: "start",
};
</script>

<template>
  <UiContainer
    v-if="fields.items && fields.items.length"
    class="events-carousel relative"
    :class="{ 'bg-navy text-white': !fields.whiteBackground }"
  >
    <section
      class="border-b-8 border-blue pb-10 lg:pb-20"
    >
      <div class="col-span-12">
        <div class="w-full items-center justify-between lg:flex">
          <h2>{{ fields.title }}</h2>

          <div
            v-if="fields.link && fields.link.content"
            class="btn-wrap btn-wrap--secondary"
          >
            <RichTextRenderer
              :document="fields.link"
              :node-renderers="nodeRenderers"
            />
          </div>
        </div>
      </div>

      <div class="mt-10">
        <carousel
          v-bind="carouselSettings"
          class="hidden lg:block"
        >
          <slide
            v-for="item in fields.items"
            :key="item?.sys.id"
            class="!items-start pr-6"
          >
            <CardBlockEvent
              v-if="item"
              :event="item"
            />
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>

        <carousel
          v-bind="carouselSettingsMobile"
          class="block lg:hidden"
        >
          <slide
            v-for="item in fields.items"
            :key="item?.sys.id"
            class="!items-start pr-6"
          >
            <CardBlockEvent
              v-if="item"
              :event="item"
              :white-background="fields.whiteBackground"
            />
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>
    </section>
  </UiContainer>
</template>

<style scoped lang="scss">
::v-deep(.carousel__prev),
::v-deep(.carousel__next) {
  background: #338cff !important;
  width: 34px;
  height: 34px;
  border-radius: 40px;
  color: #fff;
  font-size: 20px;
  top: 100%;
}

::v-deep(.carousel__prev) {
  left: auto;
  right: 50px;
}

.events-carousel:not(.bg-navy):deep(.btn) {
  @apply text-navy hover:border-navy;
}
</style>
