<script lang="ts" setup>
import type { TypeNavigationSubpage, TypePage } from "~/types/contentful";

defineProps<{
  fields: Omit<
    TypeNavigationSubpage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
    "items"
  > & {
    items: {
      fields: Pick<
        TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
        "title" | "slug" | "parent"
      >;
    }[];
  };
}>();

const menuVisible = ref(false);
</script>

<template>
  <UiContainer
    class="sub-navigation sticky top-0 z-20 mt-10 print:hidden"
    no-padding
  >
    <!-- <button
      class="flex w-full items-start justify-between border-0 border-b-4 border-b-light-royal-blue bg-white px-3 py-[12px] text-sm font-semibold text-light-royal-blue md:hidden"
      @click="() => (menuVisible = !menuVisible)"
    >
      {{ activeItemTitle ? activeItemTitle : "Menu" }}
      <NuxtIcon name="arrow" />
    </button> -->
    <div
      class="overflow-x-scroll bg-white lg:overflow-hidden"
    >
      <nav class="flex items-center justify-between">
        <div class="flex items-center gap-x-4">
          <NuxtLink
            v-for="(item) in fields.items"
            :key="item.fields.slug"
            class="whitespace-nowrap"
            :to="
              item.fields.parent
                ? getUrl(
                  item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                )
                : item.fields.slug
            "
            @click="() => (menuVisible = false)"
          >
            <span
              class="flex items-center gap-4 border-b-2 border-b-white pb-1 transition-colors"
            >
              {{ item.fields.title }}
            </span>
          </NuxtLink>
        </div>
      </nav>
    </div>
  </UiContainer>
</template>

<style lang="scss" scoped>
.sub-navigation {
  a {
    &:last-child {
      @apply max-md:pr-3;
    }

    &:hover,
    &.router-link-active {
      span {
        @apply border-blue;
      }
    }

  }
}
</style>
