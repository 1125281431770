<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  // isTypePage,
  type TypeArticleSkeleton,
} from "~/types/contentful";
import {
  hasImage,
  hasTopicOrSubtopic,
} from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  article: Entry<
    TypeArticleSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
}>();

const type = computed(() => {
  if (isTypeArticle(props.article)) {
    return props.article.fields.articleType?.fields.title;
  }

  return undefined;
});

const topicOrSubtopic = computed(() => {
  if (hasTopicOrSubtopic(props.article)) {
    return props.article.fields.topicOrSubtopic;
  }

  return undefined;
});

const date = computed(() =>
  formatDate(new Date(props.article.fields.publishDate).toISOString()),
);
</script>

<template>
  <div
    class="article-item group relative grid grid-cols-12 gap-4 lg:gap-8"
  >
    <div class="col-span-12 lg:col-span-2">
      <UiDate
        class="hidden lg:flex"
        :date="date"
      />
    </div>
    <div
      v-if="
        hasImage(article) && article.fields.image?.fields.file
      "
      class="col-span-12 md:col-span-3 lg:col-span-2"
    >
      <NuxtLink :to="getUrl(article)">
        <NuxtImg
          v-if="article.fields.image && article.fields.image.fields.file"
          class="!h-auto rounded-xl !object-contain"
          :src="article.fields.image.fields.file.url"
          :alt="article.fields.image.fields.title"
          :width="400"
          :height="284"
          fit="contain"
          loading="lazy"
        />
      </NuxtLink>
    </div>
    <div
      class="col-span-12 md:col-span-9 lg:col-span-6"
    >
      <div class="mb-4 flex items-center">
        <UiBadge
          v-if="type"
          bg-colour="#e8e8e8"
        >
          {{ type }}
        </UiBadge>
        <UiDate
          class="ms-4 flex lg:hidden"
          :date="date"
        />
      </div>
      <h4 class="mb-0">
        <NuxtLink :to="getUrl(article)">
          {{ article.fields.title }}
        </NuxtLink>
      </h4>

      <UiCardBottom
        :type="type"
        :source="article.fields.source"
        :topic-or-subtopics="topicOrSubtopic"
      />
    </div>

    <div class="btn-wrap btn-wrap--dark absolute bottom-10 right-10 hidden opacity-0 transition-opacity group-hover:opacity-100 lg:block">
      <NuxtLink
        to="/news"
        class="bg-blue"
      >
        Read more
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.article-item {
  &__image:deep(.header-info__image) {
    img {
      @apply m-auto max-h-[230px];
    }
  }
}
</style>
