<template>
  <form
    class="rounded-xl bg-navy text-center"
    name="subscribe"
    netlify
  >
    <div
      class="p-10"
    >
      <h3 class="text-white ">
        Stay updated on the latest US SIF news
      </h3>

      <div class="relative mt-10">
        <input
          id="email-subscribe"
          type="email"
          name="email"
          required
          placeholder="Enter your email"
          class="w-full rounded-md border border-white bg-white p-5 text-base text-charcoal placeholder:text-center focus:border-blue/80"
        />
        <div class="btn-wrap mt-10">
          <button
            type="submit"
            class="btn--link--blue w-full !border-blue"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
