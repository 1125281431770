<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockMediaText } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockMediaText<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer
    class="media-text"
  >
    <UiSectionTitle
      v-if="fields.displayTitle"
      :title="fields.title"
      :subtitle="fields.subtitle"
    />

    <section
      class="grid grid-cols-12 gap-6 lg:gap-24"
    >
      <div
        class="mt__image col-span-12 lg:col-span-6"
        :class="{ 'lg:order-2': !fields.mediaPosition }"
      >
        <NuxtImg
          v-if="fields.media"
          v-rellax
          data-rellax-speed="1"
          data-rellax-percentage="0.5"
          class="size-full rounded-xl object-cover lg:min-h-[409px]"
          :src="fields.media?.fields.file?.url"
          :alt="fields.media?.fields.title"
          width="600"
          loading="lazy"
        />
      </div>

      <div
        class="mt__text col-span-12 content-center items-center lg:col-span-6"
        :class="{ 'lg:order-1': !fields.mediaPosition }"
      >
        <div class="rich-text">
          <RichTextRenderer
            :document="fields.text"
            :node-renderers="nodeRenderers"
          />
        </div>

        <div class="btn-wrap btn-wrap--dark mt-8">
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </section>
  </UiContainer>
</template>
