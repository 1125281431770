<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  type TypeArticleSkeleton,
} from "~/types/contentful";
import {
  hasImage,
  hasTopicOrSubtopic,
} from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  article: Entry<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const date = computed(() =>
  formatDate(new Date(props.article.fields.publishDate).toISOString()),
);

const type = computed(() => {
  if (isTypeArticle(props.article)) {
    return props.article.fields.articleType?.fields.title;
  }

  return undefined;
});

const topicOrSubtopic = computed(() => {
  if (hasTopicOrSubtopic(props.article)) {
    return props.article.fields.topicOrSubtopic;
  }

  return undefined;
});
</script>

<template>
  <div
    class="article-item grid grid-cols-12 gap-y-4 lg:gap-8"
  >
    <div
      v-if="hasImage(article)"
      class="col-span-12 lg:col-span-3"
    >
      <NuxtLink :to="getUrl(article)">
        <NuxtImg
          v-if="article.fields.image && article.fields.image.fields.file"
          class="!h-auto rounded-xl !object-contain"
          :src="article.fields.image.fields.file.url"
          :alt="article.fields.image.fields.title"
          :width="145"
          :height="145"
          fit="contain"
          loading="lazy"
        />
      </NuxtLink>
    </div>

    <div
      class="col-span-12 flex flex-col gap-y-2 lg:col-span-9"
    >
      <UiCardTop
        :type="type"
        :date="date"
        :badge-colour="'#e8e8e8'"
      />

      <h3 class="lg:h3 text-left text-xl lg:mb-6">
        <NuxtLink :to="getUrl(article)">
          {{ article.fields.title }}
        </NuxtLink>
      </h3>

      <div class="hidden flex-row text-sm lg:flex">
        <div
          v-if="type === 'US SIF in the News'"
          class="font-bold"
        >
          {{ article.fields.source }}
        </div>

        <span
          v-if="type === 'US SIF in the News'"
          class="mx-2 -mt-1 text-xl font-bold"
        >|</span>

        <ArticleTopic
          v-if="topicOrSubtopic"
          :topic-or-subtopics="
            Array.isArray(topicOrSubtopic)
              ? topicOrSubtopic.filter(
                (
                  topicOrSubtopic,
                ): topicOrSubtopic is NonNullable<typeof topicOrSubtopic> =>
                  !!topicOrSubtopic,
              )
              : [topicOrSubtopic]
          "
          title="Topics:"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.article-item {
  h3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
