<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockEventsCarousel } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockEventsCarousel<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

const carouselSettings = {
  itemsToShow: 1,
  itemsToScroll: 1,
  snapAlign: "start",
};
</script>

<template>
  <UiContainer
    class="media-text-carousel relative overflow-hidden"
    :class="{ 'bg-navy text-white': !fields.whiteBackground }"
  >
    <section class="mt_section relative pb-10">
      <div class="col-span-12">
        <div class="w-full items-center justify-between lg:flex">
          <h2>{{ fields.title }}</h2>

          <div
            v-if="fields.link && fields.link.content"
            class="btn-wrap btn-wrap--secondary"
          >
            <RichTextRenderer
              :document="fields.link"
              :node-renderers="nodeRenderers"
            />
          </div>
        </div>
      </div>

      <div class="-mx-6 mt-10">
        <carousel
          v-bind="carouselSettings"
        >
          <slide
            v-for="item in fields.items"
            :key="item?.sys.id"
            class="!items-start pr-6"
          >
            <BlockMediaText
              v-if="item"
              :fields="{ ...item.fields, mediaPosition: true }"
            />
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>

        <NuxtIcon
          name="slash-wider"
          class="icon-slash absolute -bottom-8 left-[21%] h-auto w-1/6 text-blue max-lg:hidden"
        />
      </div>
    </section>
  </UiContainer>
</template>

<style scoped lang="scss">
.mt_section::before {
  @apply content-[""] absolute hidden md:block -bottom-36 md:left-[99%] h-[600px] w-[600px] bg-blue [clip-path:polygon(38%_0%,100%_0,100%_100%,0%_100%)];
}

::v-deep(.carousel__prev),
::v-deep(.carousel__next) {
  background: #338cff !important;
  width: 34px;
  height: 34px;
  border-radius: 40px;
  color: #fff;
  font-size: 20px;
  top: 100%;
}

::v-deep(.carousel__prev) {
  left: auto;
  right: 70px;
}

::v-deep(.carousel__next) {
  left: auto;
  right: 20px;
}

.media-text-carousel:deep(.carousel__slide section.py-10) {
  padding: 0 !important;
}

.media-text-carousel:deep(.btn-wrap--secondary a) {
  @apply text-navy border-none inline-block px-0;
}

.media-text-carousel:deep(.btn-wrap--dark a) {
  @apply text-navy border-2 border-blue hover:bg-transparent;

  &::before {
    color: #338cff;
  }
}

.media-text-carousel:deep(.section-title) {
  @apply hidden;
}

.media-text-carousel:deep(.icon-slash svg) {
  @apply h-full w-full;
}

.media-text-carousel:deep(.mt__text) {
  text-align: left;
}
</style>
