<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import { formatDate } from "~/lib/date-time";
import { isTypeArticle, type TypeArticle } from "~/types/contentful";

const props = defineProps<{
  article: TypeArticle<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const authors = computed(() =>
  props.article.fields.authors?.filter(
    (author): author is NonNullable<typeof author> => !!author,
  ),
);

const topicOrSubtopics = computed(() =>
  props.article.fields.topicOrSubtopic?.filter(
    (topicOrSubtopic): topicOrSubtopic is NonNullable<typeof topicOrSubtopic> =>
      !!topicOrSubtopic,
  ),
);

const date = computed(() =>
  formatDate(new Date(props.article.fields.publishDate).toISOString()),
);

const type = computed(() => {
  if (isTypeArticle(props.article)) {
    return props.article.fields.articleType?.fields.title;
  }

  return undefined;
});

const relatedArticleIds = computed(() =>
  props.article.fields.relatedContent?.map((item) => item?.sys.id),
);
</script>

<template>
  <UiContainer>
    <div class="article">
      <div class="article__body">
        <header class="mb-6 grid grid-cols-12 gap-y-0 text-sm lg:mb-14 lg:gap-y-4">
          <div class="col-span-12 lg:col-span-9">
            <h1 cla>
              {{ article.fields.title }}
            </h1>
            <div
              v-if="article.fields.subtitle"
              class=""
            >
              {{ article.fields.subtitle }}
            </div>
          </div>

          <div class="col-span-12 flex flex-wrap gap-2 text-sm lg:gap-x-4">
            <div class="font-bold">
              {{ type }}
            </div>

            <span class="-mt-1 text-xl font-bold text-blue">|</span>

            <div class="flex flex-row">
              <ArticleTopic
                v-if="topicOrSubtopics"
                :topic-or-subtopics="topicOrSubtopics"
                title="Topics:"
              />
            </div>

            <span class="-mt-1 text-xl font-bold text-blue">|</span>

            <div class="flex items-center">
              <NuxtIcon
                name="calendar"
                class="me-2"
              />
              {{ date }}
            </div>

            <span class="-mt-1 text-xl font-bold text-blue">|</span>

            <!-- temp solution to get around Contentful limit with amount of Content types -->
            <ArticleAuthor
              :authors="authors"
              :external-author="article.fields.externalAuthor"
            />
          </div>
        </header>

        <div class="article__content">
          <div class="grid grid-cols-12 lg:gap-x-20">
            <div class="col-span-12 lg:col-span-8">
              <div class="mb-10">
                <NuxtImg
                  v-if="article.fields.image?.fields.file && !article.fields.hideArticleImage"
                  :src="article.fields.image.fields.file.url"
                  :alt="article.fields.image.fields.title"
                  :width="864"
                  :height="440"
                  fit="contain"
                  class="rounded-xl"
                />
                <div
                  v-if="article.fields.image?.fields.description"
                  class="mt-4 text-center text-sm"
                >
                  {{ article.fields.image.fields.description }}
                </div>
              </div>

              <RichTextRenderer
                v-if="article.fields.content"
                :document="article.fields.content"
                :node-renderers="nodeRenderers"
              />

              <p
                v-if="article.fields.articleType?.fields.disclaimer"
                class="my:10 rounded-lg bg-charcoal/5 p-8 text-sm lg:my-20 lg:p-8"
              >
                <span class="me-2 font-bold">Disclaimer:</span>{{ article.fields.articleType?.fields.disclaimer }}
              </p>
            </div>

            <div class="col-span-4 hidden lg:block">
              <div class="sticky top-0">
                <ArticleSubscribe />

                <ArticleRelated
                  v-if="topicOrSubtopics?.length"
                  :article-id="article.sys.id"
                  :topic-or-subtopic-ids="[topicOrSubtopics?.[0]?.sys.id ?? '']"
                  :limit="3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UiContainer>

  <UiContainer v-if="article.fields.relatedContent">
    <div class="">
      <h2 class="mb-10 lg:mb-20">
        Related Articles
      </h2>

      <ArticleCarousel
        :related-article-ids="relatedArticleIds"
      />
    </div>
  </UiContainer>
</template>

<style scoped lang="scss">
.article {
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 160px;
  //   width: 0;
  //   height: 0;
  //   border-top: 300px solid #4d90ff;
  //   border-right: 80px solid transparent;
  // }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   top: 200px;
  //   width: 0;
  //   height: 0;
  //   border-bottom: 400px solid #f9f9fa;
  //   border-left: 170px solid transparent;
  // }
}
</style>
